import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdCancel,
  MdDelete,
  MdImage,
  MdMenu,
} from "react-icons/md";

import "../styles/Products.css";
import "../styles/Sidebar.css";
import { useLocation, useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";
import AutoresizeTextarea from "../components/AutoresizeTextarea";

export default function Products({
  isMobile,
  products,
  apiURL,
  reloadProducts,
  setReloadProducts,
  page,
  variationProducts,
  setVariationProducts,
  authToken,
}) {
  const location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/login");
    }
  }, [authToken]);

  const [deleteButtonShown, setDeleteButtonShown] = useState(false);
  const [activeID, setActiveID] = useState("");
  const [internalID, setInternalID] = useState("");
  const [variationVonID, setVariationVonID] = useState("");
  const [titel, setTitel] = useState("");
  const [zustand, SetZustand] = useState("");
  const [gewicht, setGewicht] = useState("");
  const [bilder, setBilder] = useState([]);
  const [vorrat, setVorrat] = useState("");
  const [bezugspunkt, setBezugspunkt] = useState("");
  const [einkaufspreis, setEinkaufspreis] = useState("");
  const [verkaufspreis, setVerkaufspreis] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");

  const [energie_kj, setEnergie_kj] = useState("");
  const [energie_kcal, setEnergie_kcal] = useState("");
  const [fett, setFett] = useState("");
  const [gesaettigt_fett, setGesaettigt_fett] = useState("");
  const [einfachungesaettigt_fett, setEinfachungesaettigt_fett] = useState("");
  const [mehrfachungesaettigt_fett, setMehrfachungesaettigt_fett] =
    useState("");
  const [kohlenhydrate, setKohlenhydrate] = useState("");
  const [zucker, setZucker] = useState("");
  const [eiweiss, setEiweiss] = useState("");
  const [salz, setSalz] = useState("");
  const [zutatenliste, setZutatenliste] = useState("");
  const [nettofuellmenge, setNettofuellmenge] = useState("");
  const [hersteller, setHersteller] = useState("");
  const [preis_pro_kilo, setPreis_pro_kilo] = useState("");
  const [ballaststoffe, setBallaststoffe] = useState("");
  const [calcium, setCalcium] = useState("");
  const [natrium, setNatrium] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [mediaImageURL, setMediaImageURL] = useState("");
  const [imageUploads, setImageUploads] = useState([]);

  const setActiveElement = (element) => {
    setActiveID(element.id);
    setInternalID(element.internal_id);
    setVariationVonID(element.variationVonID);
    setTitel(element.titel);
    SetZustand(element.zustand);
    setGewicht(element.gewicht);
    setBezugspunkt(element.bezugspunkt);
    setVorrat(element.vorrat);
    setEinkaufspreis(element.einkaufspreis);
    setVerkaufspreis(element.verkaufspreis);
    setCategory(element.category);
    setDescription(element.beschreibung);
    setDeleteButtonShown(false);

    setEnergie_kj(element.energie_kj);
    setEnergie_kcal(element.energie_kcal);
    setFett(element.fett);
    setGesaettigt_fett(element.gesaettigt_fett);
    setKohlenhydrate(element.kohlenhydrate);
    setZucker(element.zucker);
    setEiweiss(element.eiweiss);
    setSalz(element.salz);
    setNettofuellmenge(element.nettofuellmenge);
    setZutatenliste(element.zutatenliste);
    setHersteller(element.hersteller);
    setPreis_pro_kilo(element.preis_pro_kilo);
    setBallaststoffe(element.ballaststoffe);
    setCalcium(element.calcium);
    setNatrium(element.natrium);
    setEinfachungesaettigt_fett(element.einfachungesaettigt_fett);
    setMehrfachungesaettigt_fett(element.mehrfachungesaettigt_fett);

    let newArray = [];
    JSON.parse(element.bilder).map((pic) => newArray.push(pic.url));
    setBilder(newArray);
    setMediaImageURL(JSON.parse(element.bilder)[0].url);
    setImageUploads([]);
  };

  useEffect(() => {
    if (!isEmpty(products) && !isEmpty(activeID)) {
      for (let i = 0; i < products.length; i++) {
        if (products[i].id == activeID) {
          let newArray = [];
          JSON.parse(products[i].bilder).map((pic) => newArray.push(pic.url));
          setBilder(newArray);
        }
      }
    }
  }, [products]);

  const handleUpload = async (imgURL) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let pics = [];
    bilder.map((img) => pics.push({ url: img }));
    if (!isEmpty(imgURL)) {
      pics.push({ url: imgURL });
    }
    const resa = await fetch(
      `${apiURL}/api/products/index.php?id=` + activeID,
      {
        method: "POST",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          bilder: JSON.stringify(pics),
          internal_id: internalID,
          variationVonID: variationVonID,
          titel: titel,
          zustand: zustand,
          gewicht: gewicht,
          bezugspunkt: bezugspunkt,
          vorrat: vorrat,
          einkaufspreis: einkaufspreis,
          verkaufspreis: verkaufspreis,
          category: category,
          beschreibung: description,

          energie_kj: energie_kj,
          energie_kcal: energie_kcal,
          fett: fett,
          gesaettigt_fett: gesaettigt_fett,
          einfachungesaettigt_fett: einfachungesaettigt_fett,
          mehrfachungesaettigt_fett: mehrfachungesaettigt_fett,
          kohlenhydrate: kohlenhydrate,
          zucker: zucker,
          eiweiss: eiweiss,
          salz: salz,
          zutatenliste: zutatenliste,
          nettofuellmenge: nettofuellmenge,
          hersteller: hersteller,
          preis_pro_kilo: preis_pro_kilo,
          ballaststoffe: ballaststoffe,
          calcium: calcium,
          natrium: natrium,
        }),
      }
    );

    await resa.text().then((value) => uploadEnds(value));
  };

  const addNewProduct = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const resa = await fetch(`${apiURL}/api/products/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        titel: "New Product",
        internal_id: products.length + variationProducts.length + 1,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const duplicateProduct = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let pics = [];
    bilder.map((img) => pics.push({ url: img }));

    const resa = await fetch(`${apiURL}/api/products/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        titel: "New Product",

        bilder: JSON.stringify(pics),
        internal_id: products.length + variationProducts.length + 1,
        variationVonID: variationVonID,
        titel: "Kopie von " + titel,
        zustand: zustand,
        gewicht: gewicht,
        bezugspunkt: bezugspunkt,
        vorrat: vorrat,
        einkaufspreis: einkaufspreis,
        verkaufspreis: verkaufspreis,
        category: category,
        beschreibung: description,

        energie_kj: energie_kj,
        energie_kcal: energie_kcal,
        fett: fett,
        gesaettigt_fett: gesaettigt_fett,
        einfachungesaettigt_fett: einfachungesaettigt_fett,
        mehrfachungesaettigt_fett: mehrfachungesaettigt_fett,
        kohlenhydrate: kohlenhydrate,
        zucker: zucker,
        eiweiss: eiweiss,
        salz: salz,
        zutatenliste: zutatenliste,
        nettofuellmenge: nettofuellmenge,
        hersteller: hersteller,
        preis_pro_kilo: preis_pro_kilo,
        ballaststoffe: ballaststoffe,
        calcium: calcium,
        natrium: natrium,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    setReloadProducts(!reloadProducts);
    if (isMobile) {
      setActiveID("");
    }

    if (value != "") {
      setImageUploads([]);
    }
  };

  useEffect(() => {
    var productList = document.getElementById("productList");
    var productDetail = document.getElementById("productDetail");

    if (productList != null && productDetail != null) {
      if (isMobile && activeID == "") {
        productDetail.classList.add("d-none");
        productList.classList.remove("d-none");
      } else if (isMobile) {
        productDetail.classList.remove("d-none");
        productList.classList.add("d-none");
      } else {
        productList.classList.remove("d-none");
        productDetail.classList.remove("d-none");
      }
    }
  }, [isMobile, activeID]);

  const vorratsliste = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];

  const zustandsliste = ["gefroren", "frisch", ""];

  const kategorien = [
    "Fleisch",
    "Saucen",
    "Butter",
    "Brot/Buns",
    "Grillequipment",
    "Getränke",
    "Gewürze/Rubs",
    "Gemüse",
    "Vegan/Vegetarisch",
    "Utensilien",
  ];

  const insertLocalImage = (e) => {
    if (e.target.files.length !== 0) {
      let file = e.target.files[0];
      let filetype = "image";
      let newArray = [];
      imageUploads.map((imgObj) => newArray.push(imgObj));
      newArray.push({
        localURL: URL.createObjectURL(file),
        files: file,
        filetype: filetype,
      });
      setImageUploads(newArray);
      setMediaImageURL(URL.createObjectURL(file));
    } else {
      return;
    }

    return;
  };

  const handleUploadForImage = async () => {
    setIsLoading(true);

    const files = document.getElementById("imgInp").files;
    const formData = new FormData();
    if (!isEmpty(imageUploads)) {
      setMediaImageURL(imageUploads[0].localURL);
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    if (files.length != 0 && !isEmpty(imageUploads)) {
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          //  Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => handleUpload(value));
    } else {
      handleUpload();
    }
  };

  const removeImage = () => {
    let newArray = [];
    for (let i = 0; i < bilder.length; i++) {
      if (bilder[i] != mediaImageURL) {
        newArray.push(bilder[i]);
      }
    }
    setBilder(newArray);
  };

  const moveImage = (direction) => {
    let searchString = mediaImageURL;
    const array = [];
    bilder.map((img) => array.push(img));
    const index = array.indexOf(searchString);
    // Check if the searchString is present in the array
    if (index === -1) {
      console.error("String not found in array");
      return array;
    }

    // Check if the searchString is not at the start or end of the array
    if (
      (index > 0 && direction === -1) ||
      (index < array.length - 1 && direction === 1)
    ) {
      // Swap the elements
      const temp = array[index];
      array[index] = array[index + direction];
      array[index + direction] = temp;

      setBilder(array);
    } else {
      console.error("Cannot move element beyond array bounds");
      return array;
    }
  };

  const resetImageUpload = () => {
    setImageUploads([]);
    setMediaImageURL(bilder[0]);
  };

  const handleDeleteButtonClicked = () => {
    setDeleteButtonShown(true);
  };

  const deleteProduct = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const resa = await fetch(
      `${apiURL}/api/products/index.php?id=` + activeID,
      {
        method: "POST",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          status: 0,
        }),
      }
    );

    await resa.text().then((value) => uploadEnds(value));
  };

  const checkFileExtension = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "svg"].includes(fileExtension)) {
      return "photo";
    } else if (["mov", "mp4"].includes(fileExtension)) {
      return "video";
    }
  };

  const checkFileTypeAtLocalURL = () => {
    const files = document.getElementById("imgInp").files;
    if (!isEmpty(files)) {
      if (files[0].type.substring(0, 5) == "image") {
        return "photo";
      } else {
        return "video";
      }
    }
  };

  return (
    <div className="page-divider">
      <div className="sidebar">
        <div className="sidebar_top">
          {!isMobile && (
            <div className="" style={{ paddingLeft: "14px" }}>
              grillchef CRM
            </div>
          )}

          {!isMobile ? (
            <>
              {location.pathname.substring(0, 6) == "/" ? (
                <div className="sidebar_top_navpoint_active">Produkte</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/")}>
                  Produkte
                </div>
              )}
              {location.pathname.substring(0, 7) == "/orders" ? (
                <div className="sidebar_top_navpoint_active"> Bestellungen</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/orders")}>
                  Bestellungen
                </div>
              )}
              {location.pathname.substring(0, 9) == "/meetings" ? (
                <div className="sidebar_top_navpoint_active"> Meetings</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/meetings")}>
                  Meetings
                </div>
              )}
            </>
          ) : (
            <>
              {activeID == "" && (
                <div className="topnav">
                  <div className="sidebar_top">
                    {location.pathname.substring(0, 6) == "/" ? (
                      <div className="sidebar_top_navpoint_active">
                        Produkte
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/")}>
                        Produkte
                      </div>
                    )}
                    {location.pathname.substring(0, 7) == "/orders" ? (
                      <div className="sidebar_top_navpoint_active">
                        {" "}
                        Bestellungen
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/orders")}>
                        Bestellungen
                      </div>
                    )}
                    {location.pathname.substring(0, 9) == "/meetings" ? (
                      <div className="sidebar_top_navpoint_active">
                        {" "}
                        Meetings
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/meetings")}>
                        Meetings
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="sidebar_bottom"></div>
      </div>
      <div className="products">
        <div className="products_left" id="productList">
          <div className="products_list">
            <div className="products_addNew">
              <div
                className="products_addNew_button"
                onClick={() => duplicateProduct()}>
                Duplicate
              </div>
              <div
                className="products_addNew_button"
                onClick={() => addNewProduct()}>
                + Add New Product
              </div>
            </div>
            {products.map((product) => (
              <>
                {product.id == activeID ? (
                  <>
                    <div className="products_list_element_active">
                      <div className="products_list_element_col">
                        {product.internal_id}
                      </div>
                      <div className="products_list_element_col">
                        {product.category}
                      </div>
                      <div className="products_list_element_col">
                        {product.titel}
                      </div>
                      <div className="products_list_element_col">
                        {" | " + product.vorrat}
                      </div>
                    </div>
                    {variationProducts.map((prod) => {
                      return (
                        prod.variationVonID == product.internal_id && (
                          <div
                            className={
                              prod.id == activeID
                                ? "products_list_element_active"
                                : "products_list_element"
                            }
                            style={{ paddingLeft: "100px" }}
                            onClick={() => setActiveElement(prod)}>
                            <div className="products_list_element_col">
                              {product.category}
                            </div>
                            <div className="products_list_element_col">
                              {prod.titel}
                            </div>
                            <div className="products_list_element_col">
                              {" | " + prod.vorrat}
                            </div>
                          </div>
                        )
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div
                      className="products_list_element"
                      onClick={() => setActiveElement(product)}>
                      <div className="products_list_element_col">
                        {product.internal_id}
                      </div>
                      <div className="products_list_element_col">
                        {product.category}
                      </div>
                      <div className="products_list_element_col">
                        {product.titel}
                      </div>
                      <div className="products_list_element_col">
                        {" | " + product.vorrat}
                      </div>
                    </div>

                    {variationProducts.map((prod) => {
                      return (
                        prod.variationVonID == product.internal_id && (
                          <div
                            className={
                              prod.id == activeID
                                ? "products_list_element_active"
                                : "products_list_element"
                            }
                            style={{ paddingLeft: "100px" }}
                            onClick={() => setActiveElement(prod)}>
                            <div className="products_list_element_col">
                              {product.category}
                            </div>
                            <div className="products_list_element_col">
                              {prod.titel}
                            </div>
                            <div className="products_list_element_col">
                              {" | " + prod.vorrat}
                            </div>
                          </div>
                        )
                      );
                    })}
                  </>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="products_right" id="productDetail">
          {activeID != "" && (
            <>
              {isMobile ? (
                <div className="topnav">
                  <div className="" onClick={() => setActiveID("")}>
                    <LeftIcon /> Zurück
                  </div>
                  {isLoading ? (
                    <div className="savebtn_loading">...Lädt</div>
                  ) : (
                    <div
                      className="savebtn"
                      onClick={() => handleUploadForImage()}>
                      Speichern
                    </div>
                  )}
                </div>
              ) : (
                <div className="topnav">
                  {isLoading ? (
                    <div className="savebtn_loading">...Lädt</div>
                  ) : (
                    <div
                      className="savebtn"
                      onClick={() => handleUploadForImage()}>
                      Speichern
                    </div>
                  )}
                </div>
              )}
              {checkFileExtension(mediaImageURL) == "photo" ? (
                <img
                  className="createService_inputBtn_img"
                  style={{ width: "calc(100% - 24px)" }}
                  src={mediaImageURL}
                />
              ) : (
                <video
                  controls={true}
                  preload="metadata"
                  className={"createService_inputBtn_img"}
                  style={{
                    width: "calc(100% - 24px)",
                    backgroundColor: "grey",
                  }}
                  id={mediaImageURL}
                  autoPlay={false}>
                  <source src={mediaImageURL + "#t=0.01"} type={`video/mp4`} />
                  Your browser does not support the video tag.
                </video>
              )}
              <div className="creator_previewimages">
                {isEmpty(imageUploads) && (
                  <label htmlFor={"imgInp"} className="">
                    <div className="createService_inputlabel">
                      <ImageIcon /> + Upload
                    </div>
                  </label>
                )}

                {bilder.map((creatorimg) => (
                  <>
                    {checkFileExtension(creatorimg) == "photo" ? (
                      <div
                        className="creator_previewimages_imgbox"
                        onClick={() => setMediaImageURL(creatorimg)}>
                        <img
                          src={creatorimg}
                          className={
                            mediaImageURL == creatorimg
                              ? "creator_previewimages_imgbox_img_active"
                              : "creator_previewimages_imgbox_img"
                          }
                        />
                        {mediaImageURL == creatorimg && bilder.length > 1 && (
                          <div className="creator_previewimages_imgbox_nav">
                            <div
                              className="creator_previewimages_imgbox_nav_btn"
                              onClick={() => removeImage()}>
                              <DeleteIcon />
                            </div>
                            <div
                              className="creator_previewimages_imgbox_nav_btn"
                              onClick={() => moveImage(-1)}>
                              <LeftIcon />
                            </div>
                            <div
                              className="creator_previewimages_imgbox_nav_btn"
                              onClick={() => moveImage(1)}>
                              <RightIcon />
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        className="creator_previewimages_imgbox"
                        onClick={() => setMediaImageURL(creatorimg)}>
                        <video
                          controls={false}
                          preload="metadata"
                          className={
                            mediaImageURL == creatorimg
                              ? "creator_previewimages_imgbox_img_active"
                              : "creator_previewimages_imgbox_img"
                          }
                          style={{ backgroundColor: "grey" }}
                          id={creatorimg}
                          autoPlay={false}>
                          <source
                            src={creatorimg + "#t=0.01"}
                            type={`video/mp4`}
                          />
                          Your browser does not support the video tag.
                        </video>

                        {mediaImageURL == creatorimg && bilder.length > 1 && (
                          <div className="creator_previewimages_imgbox_nav">
                            <div
                              className="creator_previewimages_imgbox_nav_btn"
                              onClick={() => removeImage()}>
                              <DeleteIcon />
                            </div>
                            <div
                              className="creator_previewimages_imgbox_nav_btn"
                              onClick={() => moveImage(-1)}>
                              <LeftIcon />
                            </div>
                            <div
                              className="creator_previewimages_imgbox_nav_btn"
                              onClick={() => moveImage(1)}>
                              <RightIcon />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ))}
                {imageUploads.map((creatorimg) => (
                  <>
                    <div
                      className="creator_previewimages_imgbox"
                      onClick={() => setMediaImageURL(creatorimg.localURL)}>
                      {checkFileTypeAtLocalURL() == "photo" ? (
                        <img
                          className={
                            mediaImageURL == creatorimg.localURL
                              ? "creator_previewimages_imgbox_img_active"
                              : "creator_previewimages_imgbox_img"
                          }
                          src={creatorimg.localURL}
                        />
                      ) : (
                        <video
                          controls={false}
                          preload="metadata"
                          className={
                            mediaImageURL == creatorimg.localURL
                              ? "creator_previewimages_imgbox_img_active"
                              : "creator_previewimages_imgbox_img"
                          }
                          style={{ backgroundColor: "grey" }}
                          id={creatorimg.localURL}
                          autoPlay={false}>
                          <source
                            src={creatorimg.localURL + "#t=0.01"}
                            type={`video/mp4`}
                          />
                          Your browser does not support the video tag.
                        </video>
                      )}

                      <div className="creator_previewimages_imgbox_nav">
                        <div className="createService_inputBtn_cancelBtn">
                          <CancelIcon onClick={() => resetImageUpload()} />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="inputrow">
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .mp4"
                  id={"imgInp"}
                  name="files[]"
                  className="createService_inputBtn d-none"
                  onChange={(e) => insertLocalImage(e)}
                />
              </div>
              <div className=""> internal id / artikelnummer</div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={internalID}
                  onChange={(e) => setInternalID(e.target.value)}
                />
              </div>
              <div className=""> Übergeordnetes Produkt</div>
              <div className="inputrow">
                <select
                  onChange={(e) => setVariationVonID(e.target.value)}
                  className="inputrow_input">
                  {variationVonID == "" ? (
                    <option value="" selected>
                      Keines
                    </option>
                  ) : (
                    <option value="">Keines</option>
                  )}
                  {products.map((product) => (
                    <>
                      {variationVonID == product.internal_id &&
                      product.internal_id != 0 ? (
                        <option value={product.internal_id} selected>
                          {product.titel}
                        </option>
                      ) : (
                        <option value={product.internal_id}>
                          {product.titel}
                        </option>
                      )}
                      +
                    </>
                  ))}
                </select>
              </div>
              <div className=""> Kategorie</div>
              <div className="inputrow">
                <select
                  onChange={(e) => setCategory(e.target.value)}
                  className="inputrow_input">
                  {category == "" ? (
                    <option value="" selected>
                      Keine
                    </option>
                  ) : (
                    <option value="">Keine</option>
                  )}
                  {kategorien.map((cat) => (
                    <>
                      {category == cat ? (
                        <option value={cat} selected>
                          {cat}
                        </option>
                      ) : (
                        <option value={cat}>{cat}</option>
                      )}
                      +
                    </>
                  ))}
                </select>
              </div>
              <div className=""> Titel</div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={titel}
                  onChange={(e) => setTitel(e.target.value)}
                />
              </div>
              <div className=""> Produktbeschreibung</div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className=""> Zustand</div>
              <div className="inputrow">
                <select
                  onChange={(e) => SetZustand(e.target.value)}
                  className="inputrow_input">
                  {zustandsliste.map((stand) => (
                    <>
                      {zustand == stand && (
                        <option value={stand} selected>
                          {stand}
                        </option>
                      )}
                      <option value={stand}>{stand}</option>
                    </>
                  ))}
                </select>
              </div>
              <div className=""> Gewicht in gramm</div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={gewicht}
                  onChange={(e) => setGewicht(e.target.value)}
                />
              </div>
              <div className=""> Vorrat</div>
              <div className="inputrow">
                <select
                  onChange={(e) => setVorrat(e.target.value)}
                  className="inputrow_input">
                  {vorratsliste.map((nummer) => (
                    <>
                      {vorrat == nummer && (
                        <option value={vorrat} selected>
                          {vorrat}
                        </option>
                      )}
                      <option value={nummer}>{nummer}</option>
                    </>
                  ))}
                </select>
              </div>
              <div className=""> Bezugspunkt Notiz</div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={bezugspunkt}
                  onChange={(e) => setBezugspunkt(e.target.value)}
                />
              </div>
              <div className=""> Einkaufspreis in Cent</div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={einkaufspreis}
                  onChange={(e) => setEinkaufspreis(e.target.value)}
                />
              </div>
              <div className=""> Verkaufspreis in Cent</div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={verkaufspreis}
                  onChange={(e) => setVerkaufspreis(e.target.value)}
                />
              </div>
              <div className=""> Verkaufspreis pro Kilogramm in Cent</div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={preis_pro_kilo}
                  onChange={(e) => setPreis_pro_kilo(e.target.value)}
                />
              </div>

              <div className=""> Nährwerte auf 100g</div>
              <div className="inputrow" style={{ height: "auto" }}>
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="Energie in kJ"
                  value={energie_kj}
                  onChange={(e) => setEnergie_kj(e.target.value)}
                />
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="Energie in kcal"
                  value={energie_kcal}
                  onChange={(e) => setEnergie_kcal(e.target.value)}
                />
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="Fett in g"
                  value={fett}
                  onChange={(e) => setFett(e.target.value)}
                />
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="Davon gesättigte Fettsäuren in g"
                  value={gesaettigt_fett}
                  onChange={(e) => setGesaettigt_fett(e.target.value)}
                />
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="Davon einfach ungesättigte Fettsäuren in g"
                  value={einfachungesaettigt_fett}
                  onChange={(e) => setEinfachungesaettigt_fett(e.target.value)}
                />
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="Davon mehrfach ungesättigte Fettsäuren in g"
                  value={mehrfachungesaettigt_fett}
                  onChange={(e) => setMehrfachungesaettigt_fett(e.target.value)}
                />
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="Kohlenhydrate in g"
                  value={kohlenhydrate}
                  onChange={(e) => setKohlenhydrate(e.target.value)}
                />
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="davon Zucker in g"
                  value={zucker}
                  onChange={(e) => setZucker(e.target.value)}
                />
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="Eiweiß in g"
                  value={eiweiss}
                  onChange={(e) => setEiweiss(e.target.value)}
                />
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="Salz in gramm"
                  value={salz}
                  onChange={(e) => setSalz(e.target.value)}
                />
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="Ballaststoffe in g"
                  value={ballaststoffe}
                  onChange={(e) => setBallaststoffe(e.target.value)}
                />
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="Calcium in g"
                  value={calcium}
                  onChange={(e) => setCalcium(e.target.value)}
                />
                <input
                  type="text"
                  className="inputrow_input"
                  placeholder="Natrium in g"
                  value={natrium}
                  onChange={(e) => setNatrium(e.target.value)}
                />
              </div>
              <div className=""> Zutatenliste</div>
              <div className="inputrow">
                <AutoresizeTextarea
                  text={zutatenliste}
                  setText={setZutatenliste}
                />
              </div>
              <div className="">
                {" "}
                Nettofüllmenge in gramm (Bei vorverpackten Produkten)
              </div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={nettofuellmenge}
                  onChange={(e) => setNettofuellmenge(e.target.value)}
                />
              </div>
              <div className=""> Hersteller</div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={hersteller}
                  onChange={(e) => setHersteller(e.target.value)}
                />
              </div>
              <div
                className=""
                style={{
                  padding: "10px",
                  backgroundColor: "white",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
                onClick={() => handleDeleteButtonClicked()}>
                Produkt löschen
              </div>
              {deleteButtonShown && (
                <div
                  className=""
                  style={{
                    padding: "10px",
                    backgroundColor: "red",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => deleteProduct()}>
                  Produkt wirklich löschen
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const CancelIcon = styled(MdCancel)`
  font-size: 33px;
  color: #575757;
`;
const DeleteIcon = styled(MdDelete)`
  font-size: 33px;
  color: #575757;
`;

const ImageIcon = styled(MdImage)`
  font-size: 33px;
  color: #575757;
`;

const LeftIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const RightIcon = styled(MdArrowForwardIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;
