import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdCancel,
  MdDelete,
  MdImage,
  MdMenu,
} from "react-icons/md";

import "../styles/Products.css";
import "../styles/Sidebar.css";
import { useLocation, useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";

export default function Orders({
  isMobile,
  products,
  apiURL,
  reloadProducts,
  setReloadProducts,
  page,
  variationProducts,
  setVariationProducts,
  orderedProducts,
  authToken,
}) {
  const location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/login");
    }
  }, [authToken]);

  const [activeID, setActiveID] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [streetname, setStreetname] = useState("");
  const [streetnumber, setStreetnumber] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [delivery_status, setDelivery_status] = useState("");
  const [delivery_tracking_number, setDelivery_tracking_number] = useState("");
  const [delivery_sent_at_unixdate, setDelivery_sent_at_unixdate] =
    useState("");
  const [internal_note, setInternal_note] = useState("");
  const [ordered_products, setOrdered_Products] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [bilder, setBilder] = useState([]);
  const [mediaImageURL, setMediaImageURL] = useState("");
  const [imageUploads, setImageUploads] = useState([]);

  const setActiveElement = (element) => {
    setActiveID(element.id);
    setCompanyname(element.companyname);
    setFirstname(element.firstname);
    setLastname(element.lastname);
    setStreetname(element.streetname);
    setStreetnumber(element.streetnumber);
    setZip(element.zip);
    setCity(element.city);
    setCountry(element.country);
    setEmail(element.email);
    setDelivery_status(element.delivery_status);
    setDelivery_tracking_number(element.delivery_tracking_number);
    setDelivery_sent_at_unixdate(element.delivery_sent_at_unixdate);
    setInternal_note(element.internal_note);
    setOrdered_Products(JSON.parse(element.ordered_products));

    let newArray = [];
    JSON.parse(element.bilder).map((pic) => newArray.push(pic.url));
    setBilder(newArray);
    setMediaImageURL(JSON.parse(element.bilder)[0].url);
    setImageUploads([]);
  };

  useEffect(() => {
    if (!isEmpty(products) && !isEmpty(activeID)) {
      for (let i = 0; i < products.length; i++) {
        if (products[i].id == activeID) {
          let newArray = [];
          JSON.parse(products[i].bilder).map((pic) => newArray.push(pic.url));
          setBilder(newArray);
        }
      }
    }
  }, [products]);

  const handleUpload = async (imgURL) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let pics = [];
    bilder.map((img) => pics.push({ url: img }));
    if (!isEmpty(imgURL)) {
      pics.push({ url: imgURL });
    }

    let delivery_sentDate = Math.floor(Date.now() / 1000);
    if (!isEmpty(delivery_sent_at_unixdate)) {
      delivery_sentDate = delivery_sent_at_unixdate;
    }

    const resa = await fetch(`${apiURL}/api/orders/index.php?id=` + activeID, {
      method: "POST",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        bilder: JSON.stringify(pics),
        delivery_status: delivery_status,
        delivery_tracking_number: delivery_tracking_number,
        delivery_sent_at_unixdate: delivery_sentDate,
        internal_note: internal_note,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const addNewProduct = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let delivery_sentDate = Math.floor(Date.now() / 1000);
    if (!isEmpty(delivery_sent_at_unixdate)) {
      delivery_sentDate = delivery_sent_at_unixdate;
    }

    const resa = await fetch(`${apiURL}/api/orders/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        delivery_status: delivery_status,
        delivery_tracking_number: delivery_tracking_number,
        delivery_sent_at_unixdate: delivery_sentDate,
        internal_note: internal_note,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const duplicateProduct = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let pics = [];
    bilder.map((img) => pics.push({ url: img }));

    let delivery_sentDate = Math.floor(Date.now() / 1000);
    if (!isEmpty(delivery_sent_at_unixdate)) {
      delivery_sentDate = delivery_sent_at_unixdate;
    }

    const resa = await fetch(`${apiURL}/api/orders/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        bilder: JSON.stringify(pics),
        delivery_status: delivery_status,
        delivery_tracking_number: delivery_tracking_number,
        delivery_sent_at_unixdate: delivery_sentDate,
        internal_note: internal_note,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    setReloadProducts(!reloadProducts);
    if (isMobile) {
      setActiveID("");
    }

    if (value != "") {
      setImageUploads([]);
    }
  };

  useEffect(() => {
    var productList = document.getElementById("productList");
    var productDetail = document.getElementById("productDetail");

    if (productList != null && productDetail != null) {
      if (isMobile && activeID == "") {
        productDetail.classList.add("d-none");
        productList.classList.remove("d-none");
      } else if (isMobile) {
        productDetail.classList.remove("d-none");
        productList.classList.add("d-none");
      } else {
        productList.classList.remove("d-none");
        productDetail.classList.remove("d-none");
      }
    }
  }, [isMobile, activeID]);

  const insertLocalImage = (e) => {
    if (e.target.files.length !== 0) {
      let file = e.target.files[0];
      let filetype = "image";
      let newArray = [];
      imageUploads.map((imgObj) => newArray.push(imgObj));
      newArray.push({
        localURL: URL.createObjectURL(file),
        files: file,
        filetype: filetype,
      });
      setImageUploads(newArray);
      setMediaImageURL(URL.createObjectURL(file));
    } else {
      return;
    }

    return;
  };

  const handleUploadForImage = async () => {
    setIsLoading(true);

    const files = document.getElementById("imgInp").files;
    const formData = new FormData();
    if (!isEmpty(imageUploads)) {
      setMediaImageURL(imageUploads[0].localURL);
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    if (files.length != 0 && !isEmpty(imageUploads)) {
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          //  Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => handleUpload(value));
    } else {
      handleUpload();
    }
  };

  const removeImage = () => {
    let newArray = [];
    for (let i = 0; i < bilder.length; i++) {
      if (bilder[i] != mediaImageURL) {
        newArray.push(bilder[i]);
      }
    }
    setBilder(newArray);
  };

  const moveImage = (direction) => {
    let searchString = mediaImageURL;
    const array = [];
    bilder.map((img) => array.push(img));
    const index = array.indexOf(searchString);
    // Check if the searchString is present in the array
    if (index === -1) {
      console.error("String not found in array");
      return array;
    }

    // Check if the searchString is not at the start or end of the array
    if (
      (index > 0 && direction === -1) ||
      (index < array.length - 1 && direction === 1)
    ) {
      // Swap the elements
      const temp = array[index];
      array[index] = array[index + direction];
      array[index + direction] = temp;

      setBilder(array);
    } else {
      console.error("Cannot move element beyond array bounds");
      return array;
    }
  };

  const resetImageUpload = () => {
    setImageUploads([]);
    setMediaImageURL(bilder[0]);
  };
  function getTodayDay() {
    let today = new Date();
    let day = today.getDate();
    return day.toString();
  }

  function formatEuroCents(value) {
    // Ensure the value is a number
    let cents = parseInt(value, 10);

    // Calculate the euros and remaining cents
    let euros = Math.floor(cents / 100);
    let remainingCents = cents % 100;

    // Ensure the remaining cents are always two digits
    let formattedCents =
      remainingCents < 10 ? "0" + remainingCents : remainingCents;

    // Return the formatted string
    return `${euros},${formattedCents}`;
  }

  function formatDate(timestamp) {
    // Create a new Date object from the timestamp
    let date = new Date(timestamp * 1000); // Convert from seconds to milliseconds

    // Options for formatting the date and time
    let options = {
      timeZone: "Europe/Berlin",
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Use 24-hour time
    };

    // Use Intl.DateTimeFormat to format the date and time
    let formatter = new Intl.DateTimeFormat("en-GB", options);
    let parts = formatter.formatToParts(date);

    // Extract the parts we need and assemble them
    let formattedDate = parts.reduce((acc, part) => {
      switch (part.type) {
        case "day":
          return acc + part.value + ".";
        case "month":
          return acc + part.value + ".";
        case "year":
          return acc + part.value + " ";
        case "hour":
          return acc + part.value + ":";
        case "minute":
          return acc + part.value;
        default:
          return acc;
      }
    }, "");

    return formattedDate;
  }

  const getProductdataByID = (id) => {
    let product = {};
    if (!isEmpty(orderedProducts)) {
      orderedProducts.map((prod) => {
        if (prod.id == id) {
          product = prod;
        }
      });
    }

    if (!isEmpty(product)) {
      return product;
    }
  };

  return (
    <div className="page-divider">
      <div className="sidebar">
        <div className="sidebar_top">
          {!isMobile && (
            <div className="" style={{ paddingLeft: "14px" }}>
              grillchef CRM
            </div>
          )}

          {!isMobile ? (
            <>
              {location.pathname.substring(0, 6) == "/" ? (
                <div className="sidebar_top_navpoint_active">Produkte</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/")}>
                  Produkte
                </div>
              )}
              {location.pathname.substring(0, 7) == "/orders" ? (
                <div className="sidebar_top_navpoint_active"> Bestellungen</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/orders")}>
                  Bestellungen
                </div>
              )}
              {location.pathname.substring(0, 9) == "/meetings" ? (
                <div className="sidebar_top_navpoint_active"> Meetings</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/meetings")}>
                  Meetings
                </div>
              )}
            </>
          ) : (
            <>
              {activeID == "" && (
                <div className="topnav">
                  <div className="sidebar_top">
                    {location.pathname.substring(0, 6) == "/" ? (
                      <div className="sidebar_top_navpoint_active">
                        Produkte
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/")}>
                        Produkte
                      </div>
                    )}
                    {location.pathname.substring(0, 7) == "/orders" ? (
                      <div className="sidebar_top_navpoint_active">
                        {" "}
                        Bestellungen
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/orders")}>
                        Bestellungen
                      </div>
                    )}
                    {location.pathname.substring(0, 9) == "/meetings" ? (
                      <div className="sidebar_top_navpoint_active">
                        {" "}
                        Meetings
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/meetings")}>
                        Meetings
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="sidebar_bottom"></div>
      </div>
      <div className="products">
        <div className="products_left" id="productList">
          <div className="products_list">
            {products.map((product) => (
              <>
                {product.id == activeID ? (
                  <>
                    <div className="products_list_element_active">
                      <div className="products_list_element_col">
                        {product.companyname + "." + product.lastname + " | "}
                      </div>
                      <div className="products_list_element_col">
                        {JSON.parse(product.ordered_products).length +
                          " bestellte Produkte | "}
                      </div>
                      <div className="products_list_element_col">
                        {product.payment_status == "paid"
                          ? "bezahlt"
                          : "nicht bezahlt"}
                      </div>
                      <div className="products_list_element_col">
                        {" | " +
                          formatEuroCents(product.payment_amount_eurocent) +
                          " € | "}
                      </div>
                      <div className="products_list_element_col">
                        {product.delivery_status == "sent" ? (
                          <div className="" style={{ color: "green" }}>
                            versandt am{" "}
                            {formatDate(product.delivery_sent_at_unixdate)}
                          </div>
                        ) : (
                          <div className="bold" style={{ color: "red" }}>
                            nicht versandt
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="products_list_element"
                      onClick={() => setActiveElement(product)}>
                      <div className="products_list_element_col">
                        {product.companyname + "." + product.lastname + " | "}
                      </div>
                      <div className="products_list_element_col">
                        {JSON.parse(product.ordered_products).length +
                          " bestellte Produkte | "}
                      </div>
                      <div className="products_list_element_col">
                        {product.payment_status == "paid"
                          ? "bezahlt"
                          : "nicht bezahlt"}
                      </div>
                      <div className="products_list_element_col">
                        {" | " +
                          formatEuroCents(product.payment_amount_eurocent) +
                          " € | "}
                      </div>

                      <div className="products_list_element_col">
                        {product.delivery_status == "sent" ? (
                          <div className="" style={{ color: "green" }}>
                            versandt am{" "}
                            {formatDate(product.delivery_sent_at_unixdate)}
                          </div>
                        ) : (
                          <div className="bold" style={{ color: "red" }}>
                            nicht versandt
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="products_right" id="productDetail">
          {activeID != "" && (
            <>
              {isMobile ? (
                <div className="topnav">
                  <div className="" onClick={() => setActiveID("")}>
                    <LeftIcon /> Zurück
                  </div>
                  {isLoading ? (
                    <div className="savebtn_loading">...Lädt</div>
                  ) : (
                    <div
                      className="savebtn"
                      onClick={() => handleUploadForImage()}>
                      Speichern
                    </div>
                  )}
                </div>
              ) : (
                <div className="topnav">
                  {isLoading ? (
                    <div className="savebtn_loading">...Lädt</div>
                  ) : (
                    <div
                      className="savebtn"
                      onClick={() => handleUploadForImage()}>
                      Speichern
                    </div>
                  )}
                </div>
              )}
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                id={"imgInp"}
                name="files[]"
                className="createService_inputBtn d-none"
                onChange={(e) => insertLocalImage(e)}
              />
              <div className=""> Paket versendet?</div>
              <div className="inputrow">
                <select
                  onChange={(e) => setDelivery_status(e.target.value)}
                  className="inputrow_input">
                  {delivery_status == "sent" ? (
                    <>
                      <option value="sent" selected>
                        {" "}
                        Versandt
                      </option>
                      <option value="not_sent"> Nicht Versandt</option>
                    </>
                  ) : (
                    <>
                      <option value="sent"> Versandt</option>
                      <option selected value={"not_sent"}>
                        {" "}
                        Nicht Versandt
                      </option>
                    </>
                  )}
                </select>
              </div>
              <div className=""> Sendungsverfolgungsnummer</div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={delivery_tracking_number}
                  onChange={(e) => setDelivery_tracking_number(e.target.value)}
                />
              </div>

              <div className=""> Interne Notiz zur Bestellung</div>
              <div className="inputrow">
                <input
                  type="text"
                  className="inputrow_input"
                  value={internal_note}
                  onChange={(e) => setInternal_note(e.target.value)}
                />
              </div>
              <div className="">Lieferadresse</div>
              <div className="">
                {companyname}
                <br />
                {firstname + " " + lastname}
                <br />
                {streetname + " " + streetnumber}
                <br />
                {zip + " " + city + " " + country}
              </div>
              <div className="">Bestellte Produkte</div>
              <div className="">
                {ordered_products.map((order) => {
                  return <div>{getProductdataByID(order).titel}</div>;
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const CancelIcon = styled(MdCancel)`
  font-size: 33px;
  color: #575757;
`;
const DeleteIcon = styled(MdDelete)`
  font-size: 33px;
  color: #575757;
`;

const ImageIcon = styled(MdImage)`
  font-size: 33px;
  color: #575757;
`;

const LeftIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const RightIcon = styled(MdArrowForwardIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;
