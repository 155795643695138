import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { isEmpty } from "./functions/isEmpty";
import useFetch from "./functions/useFetch";

import useScreenType from "./functions/useScreenType";
import { LanguageProvider } from "./contexts/LanguageContext";
import Products from "./screens/Products";
import AdTemplates from "./screens/AdTemplates";
import Media from "./screens/Media";
import Creators from "./screens/Creators";
import Orders from "./screens/Orders";
import Meetings from "./screens/Meetings";
import Login from "./screens/Login";

const developerMode = true;

export default function App() {
  let apiURL = "https://cloud.grillchef.app";
  if (developerMode) {
    apiURL = "https://www.grillchef.servist.de";
  }

  /* Auth Token */
  const [authToken, setAuthToken] = useState("");

  /* userdata */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  const [activeProductID, setActiveProductID] = useState(9999999);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredMeetings, setFilteredMeetings] = useState([]);
  const [variationProducts, setVariationProducts] = useState([]);

  const [isUploading, setIsUploading] = useState(false);

  const isMobile = useScreenType();

  const [reloadProducts, setReloadProducts] = useState(false);
  const [reloadMeetings, setReloadMeetings] = useState(false);
  const [reloadOrders, setReloadOrders] = useState(false);

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtmentora");
      setAuthToken();
    } catch (e) {
      // saving error
    }
  };

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtmentora");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setAuthToken({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  if (isEmpty(authToken) && localStorage.getItem("@jwtmentora")) {
    getData();
  }

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };
    const res = await fetch(`${apiURL}/api/authenticate`, {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const signUp = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };
    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch(`${apiURL}/api/signup.php`, {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        username: username,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Registrierung fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const {
    data: userData,
    loading: loadingUser,
    error: errorUser,
  } = useFetch(
    `${apiURL}/api/users/`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: companyData,
    loading: loadingCompanyData,
    error: errorCompanyData,
  } = useFetch(
    `${apiURL}/api/companies/?id=` + userData.companyID,
    authToken,
    "GET",
    null,
    [userData, authToken] // Additional dependencies, if needed
  );

  const {
    data: products,
    loading: loadingProducts,
    error: errorProducts,
  } = useFetch(
    `${apiURL}/api/products/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken, reloadProducts] // Additional dependencies, if needed
  );

  const {
    data: meetings,
    loading: loadingMeetings,
    error: errorMeetings,
  } = useFetch(
    `${apiURL}/api/meetings/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken, reloadMeetings] // Additional dependencies, if needed
  );

  const {
    data: orders,
    loading: loadingOrders,
    error: errorOrders,
  } = useFetch(
    `${apiURL}/api/orders/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken, reloadOrders] // Additional dependencies, if needed
  );

  useEffect(() => {
    if (!isEmpty(products)) {
      let helpArray = [];
      let helpArray2 = [];
      let copyList = [...products];
      copyList.map((prod) => {
        if (isEmpty(prod.variationVonID)) {
          helpArray.push(prod);
        } else {
          helpArray2.push(prod);
        }
      });
      setFilteredProducts(helpArray);
      setVariationProducts(helpArray2);
    }
  }, [products]);

  useEffect(() => {
    if (!isEmpty(meetings)) {
      let helpArray = [...meetings];
      setFilteredMeetings(helpArray.reverse());
    }
  }, [meetings]);

  return (
    <div className="app">
      <LanguageProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Products
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  setActiveProductID={setActiveProductID}
                  activeProductID={activeProductID}
                  products={filteredProducts}
                  reloadProducts={reloadProducts}
                  setReloadProducts={setReloadProducts}
                  variationProducts={variationProducts}
                  setVariationProducts={setVariationProducts}
                />
              }
            />
            <Route
              path="/orders"
              element={
                <Orders
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  setActiveProductID={setActiveProductID}
                  activeProductID={activeProductID}
                  products={orders}
                  reloadProducts={reloadOrders}
                  setReloadProducts={setReloadOrders}
                  variationProducts={variationProducts}
                  setVariationProducts={setVariationProducts}
                  orderedProducts={products}
                />
              }
            />
            <Route
              path="/meetings"
              element={
                <Meetings
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  setActiveProductID={setActiveProductID}
                  activeProductID={activeProductID}
                  products={filteredMeetings}
                  reloadProducts={reloadMeetings}
                  setReloadProducts={setReloadMeetings}
                  variationProducts={variationProducts}
                  setVariationProducts={setVariationProducts}
                />
              }
            />
            <Route
              path="/login"
              element={
                <Login
                  isMobile={isMobile}
                  authToken={authToken}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  signUp={signUp}
                  username={username}
                  setUsername={setUsername}
                  signIn={signIn}
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  companyData={companyData[0]}
                />
              }
            />
          </Routes>
          <></>
        </Router>
      </LanguageProvider>
    </div>
  );
}
