import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdCancel,
  MdDelete,
  MdImage,
  MdMenu,
} from "react-icons/md";

import "../styles/Products.css";
import "../styles/Sidebar.css";
import { useLocation, useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";

export default function Creators({
  isMobile,
  creators,
  apiURL,
  reloadProducts,
  setReloadProducts,
  page,
  templates,
  products,
}) {
  const [activeID, setActiveID] = useState("");
  const [internalID, setInternalID] = useState("");
  const [title_en, setTitle_en] = useState("");
  const [title_de, setTitle_de] = useState("");
  const [imageUploads, setImageUploads] = useState([]);
  const [creatorImages, setCreatorImages] = useState([]);
  const [mediaImageURL, setMediaImageURL] = useState("");
  const [instagramProfileURL, setInstagramProfileURL] = useState("");

  const [productsApproved, setProductsApproved] = useState([]);
  const [adCategoriesApproved, setAdCategoriesApproved] = useState([]);

  const [uniqueCategoriesArray, setUniqueCategoriesArray] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const setActiveElement = (element) => {
    setActiveID(element.id);
    setTitle_en(element.companyname);
    let newArray = [];
    JSON.parse(element.pictures).map((pic) => newArray.push(pic.url));
    setCreatorImages(newArray);
    setMediaImageURL(JSON.parse(element.pictures)[0].url);
    setImageUploads([]);
    setInstagramProfileURL(element.instagramProfileURL);
    setProductsApproved(JSON.parse(element.products));

    setAdCategoriesApproved(JSON.parse(element.ads));
  };

  useEffect(() => {
    if (!isEmpty(creators) && !isEmpty(activeID)) {
      for (let i = 0; i < creators.length; i++) {
        if (creators[i].id == activeID) {
          let newArray = [];
          JSON.parse(creators[i].pictures).map((pic) => newArray.push(pic.url));
          setCreatorImages(newArray);
        }
      }
    }
  }, [creators]);

  // handle upload. wenn aciveID=new, dann create, sonst update
  const handleUpload = async (imgURL) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let pics = [];
    creatorImages.map((img) => pics.push({ url: img }));
    if (!isEmpty(imgURL)) {
      pics.push({ url: imgURL });
    }

    if (activeID == "new") {
      const resa = await fetch(`${apiURL}/api/creators/index.php`, {
        method: "PUT",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          companyname: title_en,
          pictures: JSON.stringify(pics),
          products: JSON.stringify(productsApproved),
          ads: JSON.stringify(adCategoriesApproved),
          instagramProfileURL: instagramProfileURL,
        }),
      });

      await resa.text().then((value) => uploadEnds(value));
    } else {
      const resa = await fetch(
        `${apiURL}/api/creators/index.php?id=` + activeID,
        {
          method: "POST",
          headers: {
            //   Authorization: `Bearer ${authToken?.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            companyname: title_en,
            pictures: JSON.stringify(pics),
            products: JSON.stringify(productsApproved),
            ads: JSON.stringify(adCategoriesApproved),
            instagramProfileURL: instagramProfileURL,
          }),
        }
      );

      await resa.text().then((value) => uploadEnds(value));
    }
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    setReloadProducts(!reloadProducts);
    if (isMobile) {
      setActiveID("");
    }

    if (value != "") {
      setImageUploads([]);
    }
  };

  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    var productList = document.getElementById("productList");
    var productDetail = document.getElementById("productDetail");

    if (productList != null && productDetail != null) {
      if (isMobile && activeID == "") {
        productDetail.classList.add("d-none");
        productList.classList.remove("d-none");
      } else if (isMobile) {
        productDetail.classList.remove("d-none");
        productList.classList.add("d-none");
      } else {
        productList.classList.remove("d-none");
        productDetail.classList.remove("d-none");
      }
    }
  }, [isMobile, activeID]);

  const insertLocalImage = (e) => {
    let file = e.target.files[0];
    let filetype = "image";
    let newArray = [];
    imageUploads.map((imgObj) => newArray.push(imgObj));
    newArray.push({
      localURL: URL.createObjectURL(file),
      files: file,
      filetype: filetype,
    });
    setImageUploads(newArray);
    setMediaImageURL(URL.createObjectURL(file));
  };

  const handleUploadForImage = async () => {
    setIsLoading(true);

    const files = document.getElementById("imgInp").files;
    const formData = new FormData();
    if (!isEmpty(imageUploads)) {
      setMediaImageURL(imageUploads[0].localURL);
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    if (files.length != 0 && !isEmpty(imageUploads)) {
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          //  Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => handleUpload(value));
    } else {
      handleUpload();
    }
  };

  const removeImage = () => {
    let newArray = [];
    for (let i = 0; i < creatorImages.length; i++) {
      if (creatorImages[i] != mediaImageURL) {
        newArray.push(creatorImages[i]);
      }
    }
    setCreatorImages(newArray);
  };

  const moveImage = (direction) => {
    let searchString = mediaImageURL;
    const array = [];
    creatorImages.map((img) => array.push(img));
    const index = array.indexOf(searchString);
    // Check if the searchString is present in the array
    if (index === -1) {
      console.error("String not found in array");
      return array;
    }

    // Check if the searchString is not at the start or end of the array
    if (
      (index > 0 && direction === -1) ||
      (index < array.length - 1 && direction === 1)
    ) {
      // Swap the elements
      const temp = array[index];
      array[index] = array[index + direction];
      array[index + direction] = temp;

      setCreatorImages(array);
    } else {
      console.error("Cannot move element beyond array bounds");
      return array;
    }
  };

  const isInProductList = (object) => {
    return productsApproved.includes(object);
  };

  const isInCategoryList = (object) => {
    return adCategoriesApproved.includes(object);
  };

  const addProductToList = (productID) => {
    let newArray = [];
    productsApproved.map((product) => newArray.push(product));
    newArray.push(productID);
    setProductsApproved(newArray);
  };

  const removeProductFromList = (productID) => {
    let newArray = productsApproved.filter((product) => product !== productID);
    setProductsApproved(newArray);
  };

  useEffect(() => {
    // initialize category list
    setUniqueCategoriesArray([
      ...new Set(templates.map((template) => template.category)),
    ]);
  }, [templates, activeID]);

  const addToCategoryList = (categoryString) => {
    let newArray = [];
    adCategoriesApproved.map((product) => newArray.push(product));
    newArray.push(categoryString);
    setAdCategoriesApproved(newArray);
  };

  const removeFromCategoryList = (categoryString) => {
    let newArray = adCategoriesApproved.filter(
      (product) => product !== categoryString
    );
    setAdCategoriesApproved(newArray);
  };

  return (
    <div className="page-divider">
      <div className="sidebar">
        <div className="sidebar_top">
          {location.pathname.substring(0, 6) == "/" ? (
            <div className="sidebar_top_navpoint_active">Products</div>
          ) : (
            <div className="sidebar_top_navpoint" onClick={() => navigate("/")}>
              Products
            </div>
          )}
          {location.pathname.substring(0, 4) == "/ads" ? (
            <div className="sidebar_top_navpoint_active"> Ad-Templates</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/ads")}>
              Ad-Templates
            </div>
          )}
          {location.pathname.substring(0, 6) == "/media" ? (
            <div className="sidebar_top_navpoint_active">Media</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/media")}>
              Media
            </div>
          )}
          {location.pathname.substring(0, 9) == "/creators" ? (
            <div className="sidebar_top_navpoint_active">Creators</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/creators")}>
              Creators
            </div>
          )}
          {location.pathname.substring(0, 10) == "/retention" ? (
            <div className="sidebar_top_navpoint_active">Retention</div>
          ) : (
            <div
              className="sidebar_top_navpoint"
              onClick={() => navigate("/retention")}>
              Retention
            </div>
          )}
        </div>
        <div className="sidebar_bottom"></div>
      </div>
      <div className="products">
        <div className="products_left" id="productList">
          <div className="products_list">
            {creators.map((product) => (
              <>
                {product.id == activeID ? (
                  <div className="products_list_element_active">
                    <div className="products_list_element_col">
                      <img
                        src={JSON.parse(product.pictures)[0].url}
                        style={({ width: "40px" }, { height: "40px" })}
                      />
                    </div>
                    <div className="products_list_element_col">
                      {product.companyname}
                    </div>
                  </div>
                ) : (
                  <div
                    className="products_list_element"
                    onClick={() => setActiveElement(product)}>
                    <div className="products_list_element_col">
                      <img
                        src={JSON.parse(product.pictures)[0].url}
                        style={({ width: "40px" }, { height: "40px" })}
                      />
                    </div>
                    <div className="products_list_element_col">
                      {product.companyname}
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="products_right" id="productDetail">
          {isMobile && (
            <div className="" onClick={() => setActiveID("")}>
              X
            </div>
          )}
          <div className="products_addNew">
            {activeID == "new" ? (
              <div className="products_addNew_button_active">
                + Add New Product
              </div>
            ) : (
              <div
                className="products_addNew_button"
                onClick={() => setActiveID("new")}>
                + Add New Product
              </div>
            )}
          </div>

          <div className="">
            {activeID == "new" ? "Neues Produkt anlegen" : ""}
          </div>
          <div className=""> image</div>
          {!isEmpty(imageUploads) ? (
            <div className="">
              <img
                className="createService_inputBtn_img"
                style={{ width: "calc(100% - 24px)" }}
                src={mediaImageURL}
              />

              <div className="createService_inputBtn_cancelBtn">
                <CancelIcon onClick={() => setImageUploads([])} />
              </div>
            </div>
          ) : (
            <>
              <div className="" onClick={() => removeImage()}>
                <DeleteIcon />
              </div>
              <div className="" onClick={() => moveImage(-1)}>
                <LeftIcon />
              </div>
              <div className="" onClick={() => moveImage(1)}>
                <RightIcon />
              </div>
              <img
                className="createService_inputBtn_img"
                style={{ width: "calc(100% - 24px)" }}
                src={mediaImageURL}
              />
              <label htmlFor={"imgInp"} className="">
                <div className="createService_inputlabel">
                  <ImageIcon /> + Content hochladen
                </div>
              </label>
            </>
          )}
          <div className="creator_previewimages">
            {creatorImages.map((creatorimg) => (
              <div className="" onClick={() => setMediaImageURL(creatorimg)}>
                <img style={{ width: "50px" }} src={creatorimg} />
              </div>
            ))}
            {imageUploads.map((creatorimg) => (
              <div
                className=""
                onClick={() => setMediaImageURL(creatorimg.localURL)}>
                <img style={{ width: "50px" }} src={creatorimg.localURL} />
              </div>
            ))}
          </div>
          <div className="inputrow">
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              id={"imgInp"}
              name="files[]"
              className="createService_inputBtn d-none"
              onChange={(e) => insertLocalImage(e)}
            />
          </div>
          <div className=""> title_en</div>
          <div className="inputrow">
            <input
              type="text"
              className="inputrow_input"
              value={title_en}
              onChange={(e) => setTitle_en(e.target.value)}
            />
          </div>
          <div className=""> instagramProfileURL</div>
          <div className="inputrow">
            <input
              type="text"
              className="inputrow_input"
              value={instagramProfileURL}
              onChange={(e) => setInstagramProfileURL(e.target.value)}
            />
          </div>
          {activeID != "" && (
            <div className="savebtn" onClick={() => handleUploadForImage()}>
              {isLoading ? "Lädt..." : "Speichern"}
            </div>
          )}
          <div className="">Ads willing to create</div>
          <div className="">
            {!isEmpty(uniqueCategoriesArray) &&
              uniqueCategoriesArray.map((category) => (
                <>
                  {isInCategoryList(category) ? (
                    <div
                      className=""
                      style={{ backgroundColor: "#ffeedb" }}
                      onClick={() => removeFromCategoryList(category)}>
                      {category}
                    </div>
                  ) : (
                    <div
                      className=""
                      onClick={() => addToCategoryList(category)}>
                      {category}
                    </div>
                  )}
                </>
              ))}
          </div>

          <div className="">
            {products.map((product) => (
              <>
                {isInProductList(product.internal_id) ? (
                  <div
                    className=""
                    style={{ backgroundColor: "#ffeedb" }}
                    onClick={() => removeProductFromList(product.internal_id)}>
                    {product.title_en}
                  </div>
                ) : (
                  <div
                    className=""
                    onClick={() => addProductToList(product.internal_id)}>
                    {product.title_en}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const CancelIcon = styled(MdCancel)`
  font-size: 33px;
  color: #575757;
`;
const DeleteIcon = styled(MdDelete)`
  font-size: 33px;
  color: #575757;
`;

const ImageIcon = styled(MdImage)`
  font-size: 33px;
  color: #575757;
`;

const LeftIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const RightIcon = styled(MdArrowForwardIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;
