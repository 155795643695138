import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdArrowBack, MdArrowBackIos, MdMenu } from "react-icons/md";

import "../styles/Login.css";
import { useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";
import Spinner from "../components/CTA/Spinner";

export default function Login({
  authToken,
  email,
  setEmail,
  password,
  setPassword,
  signIn,
  signUp,
  username,
  setUsername,
  creatorData,
  shoppingCard,

  isUploading,
  setIsUploading,
  companyData,
}) {
  const [page, setPage] = useState("login");
  const [formIsFilled, setFormIsFilled] = useState(false);

  let navigate = useNavigate();

  // Attach an event listener when the component mounts
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the pressed key is the Enter key (key code 13)
      if (event.keyCode === 13 && formIsFilled && page == "register") {
        // Call the function when Enter key is pressed
        setIsUploading(true);
        signUp();
      } else if (event.keyCode === 13 && formIsFilled && page != "register") {
        setIsUploading(true);
        signIn();
      }
    };

    // Add event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Clean up event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [formIsFilled, page, username, email, password]);

  // userdata in state packen
  useEffect(() => {
    if (!isEmpty(authToken)) {
      navigate("/");
    }
  }, [authToken]);

  useEffect(() => {
    if (
      (page == "register" && isEmpty(username)) ||
      isEmpty(email) ||
      isEmpty(password) ||
      !validateEmail(email) ||
      password.length < 6
    ) {
      setFormIsFilled(false);
    } else {
      setFormIsFilled(true);
    }
  }, [username, email, password, page]);

  const signUpAndDisableButton = () => {
    setIsUploading(true);
    signUp();
  };

  const signInAndDisableButton = () => {
    setIsUploading(true);
    signIn();
  };

  const validateEmail = (email) => {
    if (email.length < 3) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="login">
      <div className="login_registerform">
        <div className="login_registerform_title">
          Menschen und Essen zusammenbringen
        </div>

        <input
          type="email"
          className="login_input"
          placeholder="Benutzername"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <input
          type="password"
          className="login_input"
          placeholder="Passwort"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        {formIsFilled ? (
          <>
            {!isUploading ? (
              <div
                className="login_ctabtn button"
                onClick={() => signInAndDisableButton()}>
                Einloggen{" "}
              </div>
            ) : (
              <div
                className="login_ctabtn_disabled button"
                disabled={"disabled"}>
                <Spinner type="white" />
              </div>
            )}
          </>
        ) : (
          <div className="login_ctabtn_disabled button" disabled={"disabled"}>
            Einloggen{" "}
          </div>
        )}
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBack)`
  font-size: 20px;
  color: #636363;
  margin-top: 2px;
`;

const SearchIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-top: -8px;
`;

const ArrowBackIcon = styled(MdArrowBackIos)`
  font-size: 23px;
  color: #474747;
`;
