import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdCancel,
  MdDelete,
  MdImage,
  MdMenu,
} from "react-icons/md";

import "../styles/Products.css";
import "../styles/Sidebar.css";
import { useLocation, useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";
import AutoresizeTextarea from "../components/AutoresizeTextarea";

export default function Meetings({
  isMobile,
  products,
  apiURL,
  reloadProducts,
  setReloadProducts,
  page,
  variationProducts,
  setVariationProducts,
  authToken,
}) {
  const location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/login");
    }
  }, [authToken]);

  const [activeID, setActiveID] = useState("");
  const [internalID, setInternalID] = useState("");
  const [variationVonID, setVariationVonID] = useState("");
  const [todo, setTodo] = useState("");
  const [dateDay, setDateDay] = useState("");
  const [dateMonth, setDateMonth] = useState("");
  const [dateYear, setDateYear] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [bilder, setBilder] = useState([]);
  const [mediaImageURL, setMediaImageURL] = useState("");
  const [imageUploads, setImageUploads] = useState([]);

  const setActiveElement = (element) => {
    setActiveID(element.id);
    setTodo(element.todo);
    setDateDay(element.dateDay);
    setDateMonth(element.dateMonth);
    setDateYear(element.dateYear);

    let newArray = [];
    JSON.parse(element.bilder).map((pic) => newArray.push(pic.url));
    setBilder(newArray);
    setMediaImageURL(JSON.parse(element.bilder)[0].url);
    setImageUploads([]);
  };

  useEffect(() => {
    if (!isEmpty(products) && !isEmpty(activeID)) {
      for (let i = 0; i < products.length; i++) {
        if (products[i].id == activeID) {
          let newArray = [];
          JSON.parse(products[i].bilder).map((pic) => newArray.push(pic.url));
          setBilder(newArray);
        }
      }
    }
  }, [products]);

  const handleUpload = async (imgURL) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let pics = [];
    bilder.map((img) => pics.push({ url: img }));
    if (!isEmpty(imgURL)) {
      pics.push({ url: imgURL });
    }
    const resa = await fetch(
      `${apiURL}/api/meetings/index.php?id=` + activeID,
      {
        method: "POST",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          bilder: JSON.stringify(pics),
          todo: todo,
          dateDay: dateDay,
          dateMonth: dateMonth,
          dateYear: dateYear,
        }),
      }
    );

    await resa.text().then((value) => uploadEnds(value));
  };

  const addNewProduct = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const resa = await fetch(`${apiURL}/api/meetings/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        todo: todo,
        dateDay: getTodayDay(),
        dateMonth: getCurrentMonthInGerman(),
        dateYear: getCurrentYear(),
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const duplicateProduct = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let pics = [];
    bilder.map((img) => pics.push({ url: img }));

    const resa = await fetch(`${apiURL}/api/meetings/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        bilder: JSON.stringify(pics),
        todo: todo,
        dateDay: dateDay,
        dateMonth: dateMonth,
        dateYear: dateYear,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    setReloadProducts(!reloadProducts);
    if (isMobile) {
      setActiveID("");
    }

    if (value != "") {
      setImageUploads([]);
    }
  };

  useEffect(() => {
    var productList = document.getElementById("productList");
    var productDetail = document.getElementById("productDetail");

    if (productList != null && productDetail != null) {
      if (isMobile && activeID == "") {
        productDetail.classList.add("d-none");
        productList.classList.remove("d-none");
      } else if (isMobile) {
        productDetail.classList.remove("d-none");
        productList.classList.add("d-none");
      } else {
        productList.classList.remove("d-none");
        productDetail.classList.remove("d-none");
      }
    }
  }, [isMobile, activeID]);

  const tage = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];

  const monate = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const jahre = ["2024", "2025", "2026", "2027", "2028", "2029", "2030"];

  const insertLocalImage = (e) => {
    if (e.target.files.length !== 0) {
      let file = e.target.files[0];
      let filetype = "image";
      let newArray = [];
      imageUploads.map((imgObj) => newArray.push(imgObj));
      newArray.push({
        localURL: URL.createObjectURL(file),
        files: file,
        filetype: filetype,
      });
      setImageUploads(newArray);
      setMediaImageURL(URL.createObjectURL(file));
    } else {
      return;
    }

    return;
  };

  const handleUploadForImage = async () => {
    setIsLoading(true);

    const files = document.getElementById("imgInp").files;
    const formData = new FormData();
    if (!isEmpty(imageUploads)) {
      setMediaImageURL(imageUploads[0].localURL);
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    if (files.length != 0 && !isEmpty(imageUploads)) {
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          //  Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => handleUpload(value));
    } else {
      handleUpload();
    }
  };

  const removeImage = () => {
    let newArray = [];
    for (let i = 0; i < bilder.length; i++) {
      if (bilder[i] != mediaImageURL) {
        newArray.push(bilder[i]);
      }
    }
    setBilder(newArray);
  };

  const moveImage = (direction) => {
    let searchString = mediaImageURL;
    const array = [];
    bilder.map((img) => array.push(img));
    const index = array.indexOf(searchString);
    // Check if the searchString is present in the array
    if (index === -1) {
      console.error("String not found in array");
      return array;
    }

    // Check if the searchString is not at the start or end of the array
    if (
      (index > 0 && direction === -1) ||
      (index < array.length - 1 && direction === 1)
    ) {
      // Swap the elements
      const temp = array[index];
      array[index] = array[index + direction];
      array[index + direction] = temp;

      setBilder(array);
    } else {
      console.error("Cannot move element beyond array bounds");
      return array;
    }
  };

  const resetImageUpload = () => {
    setImageUploads([]);
    setMediaImageURL(bilder[0]);
  };

  function getTodayDay() {
    let today = new Date();
    let day = today.getDate();
    return day.toString();
  }

  function getCurrentMonthInGerman() {
    const monthsInGerman = [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ];

    let today = new Date();
    let monthIndex = today.getMonth(); // getMonth() returns month from 0-11
    return monthsInGerman[monthIndex];
  }

  function getCurrentYear() {
    let today = new Date();
    let year = today.getFullYear();
    return year.toString();
  }

  return (
    <div className="page-divider">
      <div className="sidebar">
        <div className="sidebar_top">
          {!isMobile && (
            <div className="" style={{ paddingLeft: "14px" }}>
              grillchef CRM
            </div>
          )}

          {!isMobile ? (
            <>
              {location.pathname.substring(0, 6) == "/" ? (
                <div className="sidebar_top_navpoint_active">Produkte</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/")}>
                  Produkte
                </div>
              )}
              {location.pathname.substring(0, 7) == "/orders" ? (
                <div className="sidebar_top_navpoint_active"> Bestellungen</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/orders")}>
                  Bestellungen
                </div>
              )}
              {location.pathname.substring(0, 9) == "/meetings" ? (
                <div className="sidebar_top_navpoint_active"> Meetings</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/meetings")}>
                  Meetings
                </div>
              )}
            </>
          ) : (
            <>
              {activeID == "" && (
                <div className="topnav">
                  <div className="sidebar_top">
                    {location.pathname.substring(0, 6) == "/" ? (
                      <div className="sidebar_top_navpoint_active">
                        Produkte
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/")}>
                        Produkte
                      </div>
                    )}
                    {location.pathname.substring(0, 7) == "/orders" ? (
                      <div className="sidebar_top_navpoint_active">
                        {" "}
                        Bestellungen
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/orders")}>
                        Bestellungen
                      </div>
                    )}
                    {location.pathname.substring(0, 9) == "/meetings" ? (
                      <div className="sidebar_top_navpoint_active">
                        {" "}
                        Meetings
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/meetings")}>
                        Meetings
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="sidebar_bottom"></div>
      </div>
      <div className="products">
        <div className="products_left" id="productList">
          <div className="products_list">
            <div className="products_addNew">
              <div
                className="products_addNew_button"
                onClick={() => duplicateProduct()}>
                Duplicate
              </div>
              <div
                className="products_addNew_button"
                onClick={() => addNewProduct()}>
                + Add New Product
              </div>
            </div>
            {products.map((product) => (
              <>
                {product.id == activeID ? (
                  <>
                    <div className="products_list_element_active">
                      <div className="products_list_element_col">
                        {product.dateDay + "." + product.dateMonth}
                      </div>
                      <div
                        className="products_list_element_col"
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}>
                        {" " + product.todo}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="products_list_element"
                      onClick={() => setActiveElement(product)}>
                      <div className="products_list_element_col">
                        {product.dateDay + "." + product.dateMonth}
                      </div>
                      <div
                        className="products_list_element_col"
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}>
                        {" " + product.todo}
                      </div>
                    </div>
                  </>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="products_right" id="productDetail">
          {activeID != "" && (
            <>
              {isMobile ? (
                <div className="topnav">
                  <div className="" onClick={() => setActiveID("")}>
                    <LeftIcon /> Zurück
                  </div>
                  {isLoading ? (
                    <div className="savebtn_loading">...Lädt</div>
                  ) : (
                    <div
                      className="savebtn"
                      onClick={() => handleUploadForImage()}>
                      Speichern
                    </div>
                  )}
                </div>
              ) : (
                <div className="topnav">
                  {isLoading ? (
                    <div className="savebtn_loading">...Lädt</div>
                  ) : (
                    <div
                      className="savebtn"
                      onClick={() => handleUploadForImage()}>
                      Speichern
                    </div>
                  )}
                </div>
              )}
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                id={"imgInp"}
                name="files[]"
                className="createService_inputBtn d-none"
                onChange={(e) => insertLocalImage(e)}
              />
              <div className=""> Meeting Tag</div>
              <div className="inputrow">
                <select
                  onChange={(e) => setDateDay(e.target.value)}
                  className="inputrow_input">
                  {tage.map((tag) => (
                    <>
                      {dateDay == tag ? (
                        <option value={tag} selected>
                          {tag}
                        </option>
                      ) : (
                        <option value={tag}>{tag}</option>
                      )}
                      +
                    </>
                  ))}
                </select>
              </div>
              <div className=""> Meeting Monat</div>
              <div className="inputrow">
                <select
                  onChange={(e) => setDateMonth(e.target.value)}
                  className="inputrow_input">
                  {monate.map((tag) => (
                    <>
                      {dateMonth == tag ? (
                        <option value={tag} selected>
                          {tag}
                        </option>
                      ) : (
                        <option value={tag}>{tag}</option>
                      )}
                      +
                    </>
                  ))}
                </select>
              </div>
              <div className=""> Meeting Jahr</div>
              <div className="inputrow">
                <select
                  onChange={(e) => setDateYear(e.target.value)}
                  className="inputrow_input">
                  {jahre.map((tag) => (
                    <>
                      {dateYear == tag ? (
                        <option value={tag} selected>
                          {tag}
                        </option>
                      ) : (
                        <option value={tag}>{tag}</option>
                      )}
                      +
                    </>
                  ))}
                </select>
              </div>
              <div className=""> TODO zum nächsten Termin</div>
              <div className="inputrow">
                <AutoresizeTextarea text={todo} setText={setTodo} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const CancelIcon = styled(MdCancel)`
  font-size: 33px;
  color: #575757;
`;
const DeleteIcon = styled(MdDelete)`
  font-size: 33px;
  color: #575757;
`;

const ImageIcon = styled(MdImage)`
  font-size: 33px;
  color: #575757;
`;

const LeftIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const RightIcon = styled(MdArrowForwardIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;
