import React, { useEffect } from "react";

export default function AutoresizeTextarea({ text, setText }) {
  // Function to handle the textarea input changes
  const handleInputChange = (event) => {
    const textarea = event.target;
    setText(textarea.value);
    textarea.style.height = "auto"; // Reset the height to 'auto' to allow for shrinkage
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the scrollHeight of the content
  };

  // useEffect hook to run handleInputChange when the component mounts or text changes
  useEffect(() => {
    const textarea = document.querySelector("textarea"); // Get the textarea element
    if (textarea) {
      textarea.style.height = "auto"; // Reset the height to 'auto' for proper calculation
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the content's scrollHeight
    }
  }, [text]); // Dependency array includes text to trigger effect on changes

  return (
    <textarea
      style={{ width: "100%", height: "auto", overflow: "hidden" }}
      value={text}
      onChange={handleInputChange}
      placeholder="Type here..."
      rows={1} // Optional: sets the minimum number of rows
    />
  );
}
